import React from "react"
import { ExtLink } from "./atoms"

export const Contact = () => {
  return (
    <div className="mt-32 full-width-container bg-secondary">
      <div className="container max-w-screen-xl mx-auto pt-16 text-gray-900">
        <h1 className="text-2xl font-semibold text-center text-primary">
          Get in Touch{" "}
        </h1>
        <p className="px-4 py-0 mt-4 text-center text-secondary">
          Simply shoot me an email at:
        </p>
        <p className="px-4 py-0 mt-0 mb-0 text-center text-tertiary font-mono">longteng.zhang * tudublin - ie</p>
        <p className="px-4 py-0 mt-0 text-center text-secondary text-xs italic">
          (you know what to do)
        </p>
        <p className="px-4 py-2 mt-1 text-center text-secondary">
          Or here's my profile on other sites:{" "}
          <ExtLink link="https://www.linkedin.com/in/Longteng.Zhang" newTab>
            LinkedIn
          </ExtLink>
          ,{" "}
          <ExtLink link="https://www.researchgate.net/profile/Longteng_Zhang4" newTab>
            ResearchGate
          </ExtLink>
          ,{" "}
          <ExtLink link="https://orcid.org/0000-0002-8274-880X" newTab>
            ORCiD
          </ExtLink>
        </p>
        {/* <div className="mx-auto  pt-10 flex justify-center">
          <Button width="widest" link="mailto:mrg6nm2ji@relay.firefox.com">
            <span className=" text-2xl font-semibold text-on-accent">
              Say hello
            </span>
          </Button>
        </div> */}
      </div>
    </div>
  )
}

export default Contact
